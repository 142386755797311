import React, { useState } from 'react'
import Box from '@bit/mui-org.material-ui.box'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Tab from '@bit/mui-org.material-ui.tab'
import Tabs from '@bit/mui-org.material-ui.tabs'
import Typography from '@bit/mui-org.material-ui.typography'
import './tabs.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={10}>{children}</Box>}
    </Typography>
  )
}

export default function Features(props) {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    }
  }

  const data = useStaticQuery(graphql`
    {
      poolingIcon: file(relativePath: { eq: "pooling-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 61, layout: FIXED)
        }
      }
      pooling: file(relativePath: { eq: "pooling-img.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 568, layout: CONSTRAINED)
        }
      }
      installationsIcon: file(relativePath: { eq: "install-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 61, layout: FIXED)
        }
      }
      installations: file(relativePath: { eq: "install.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 568, layout: CONSTRAINED)
        }
      }
      maintenanceIcon: file(relativePath: { eq: "maintenance-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 61, layout: FIXED)
        }
      }
      maintenance: file(relativePath: { eq: "maintenance-img.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 568, layout: CONSTRAINED)
        }
      }
      insuranceIcon: file(relativePath: { eq: "insurance-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 61, layout: FIXED)
        }
      }
      insurance: file(relativePath: { eq: "insurance.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 568, layout: CONSTRAINED)
        }
      }
      requiredIcon: file(relativePath: { eq: "required-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 61, layout: FIXED)
        }
      }
      required: file(relativePath: { eq: "no-land-required.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 568, layout: CONSTRAINED)
        }
      }
      operationsIcon: file(relativePath: { eq: "operations-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 61, layout: FIXED)
        }
      }
      operations: file(relativePath: { eq: "operations-img.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 568, layout: CONSTRAINED)
        }
      }
      creditsIcon: file(relativePath: { eq: "credits-card-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 72, layout: FIXED)
        }
      }
      credits: file(relativePath: { eq: "instant-credits.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 568, layout: CONSTRAINED)
        }
      }
      liquidIcon: file(relativePath: { eq: "liquid-investment-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 55, layout: FIXED)
        }
      }
      liquid: file(relativePath: { eq: "liquid-img.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 568, layout: CONSTRAINED)
        }
      }
      noRiskIcon: file(relativePath: { eq: "no-risk-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 55, layout: FIXED)
        }
      }
      noRisk: file(relativePath: { eq: "no-risk.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 568, layout: CONSTRAINED)
        }
      }
      noLimitsIcon: file(relativePath: { eq: "no-limits-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 55, layout: FIXED)
        }
      }
      noLimits: file(relativePath: { eq: "no-limits.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 568, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <>
      <section className="synced-cloud-wrap">
        <div className="container">
          <div className="synced-cloud-bg">
            <h2>
              CLOUDSOLAR <span>FEATURES</span>
            </h2>
            <div className="synced-slider">
              <div className="feature-tabs">
                <Tabs
                  variant="scrollable"
                  scrollButtons="on"
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  // textColor="primary"
                >
                  <Tab
                    label={
                      <div className="">
                        <div className="content-img">
                          {/* <GatsbyImage
                          image={data.poolingIcon.childImageSharp.gatsbyImageData}
                          alt="Cloud Solar pooling"
                        /> */}
                          <StaticImage
                            src="../../../images/pooling-icon.png"
                            layout="fixed"
                            width={61}
                            height={61}
                            alt="Cloud Solar pooling"
                          />
                          <strong>Revenue Pooling</strong>
                        </div>
                      </div>
                    }
                    {...a11yProps(0)}
                    className="box-shadow-none btn-tab-item"
                  />
                  <Tab
                    label={
                      <div className="">
                        <div className="content-img">
                          <GatsbyImage
                            image={
                              data.installationsIcon.childImageSharp
                                .gatsbyImageData
                            }
                            alt="Cloud Solar array"
                          />
                          <strong>Low Cost Installations</strong>
                        </div>
                      </div>
                    }
                    {...a11yProps(0)}
                    className="box-shadow-none btn-tab-item"
                  />
                  <Tab
                    label={
                      <div className="">
                        <div className="content-img">
                          <GatsbyImage
                            image={
                              data.maintenanceIcon.childImageSharp
                                .gatsbyImageData
                            }
                            alt="Cloud Solar array"
                          />
                          <strong>Maintenance Included</strong>
                        </div>
                      </div>
                    }
                    {...a11yProps(0)}
                    className="box-shadow-none btn-tab-item"
                  />
                  <Tab
                    label={
                      <div className="">
                        <div className="content-img">
                          <GatsbyImage
                            image={
                              data.insuranceIcon.childImageSharp.gatsbyImageData
                            }
                            alt="Cloud Solar array"
                          />
                          <strong>Insurance Included</strong>
                        </div>
                      </div>
                    }
                    {...a11yProps(0)}
                    className="box-shadow-none btn-tab-item"
                  />
                  <Tab
                    label={
                      <div className="">
                        <div className="content-img">
                          <GatsbyImage
                            image={
                              data.requiredIcon.childImageSharp.gatsbyImageData
                            }
                            alt="Cloud Solar array"
                          />
                          <strong>No Roof/Land Required</strong>
                        </div>
                      </div>
                    }
                    {...a11yProps(0)}
                    className="box-shadow-none btn-tab-item"
                  />
                  <Tab
                    label={
                      <div className="">
                        <div className="content-img">
                          <GatsbyImage
                            image={
                              data.operationsIcon.childImageSharp
                                .gatsbyImageData
                            }
                            alt="Cloud Solar array"
                          />
                          <strong>Managed Operations</strong>
                        </div>
                      </div>
                    }
                    {...a11yProps(0)}
                    className="box-shadow-none btn-tab-item"
                  />
                  <Tab
                    label={
                      <div className="">
                        <div className="content-img">
                          <GatsbyImage
                            image={
                              data.creditsIcon.childImageSharp.gatsbyImageData
                            }
                            alt="Cloud Solar credits"
                          />
                          <strong>Instant Credits</strong>
                        </div>
                      </div>
                    }
                    {...a11yProps(0)}
                    className="box-shadow-none btn-tab-item"
                  />
                  <Tab
                    label={
                      <div className="">
                        <div className="content-img">
                          <GatsbyImage
                            image={
                              data.liquidIcon.childImageSharp.gatsbyImageData
                            }
                            alt="Cloud Solar liquid"
                          />
                          <strong>Liquid Asset</strong>
                        </div>
                      </div>
                    }
                    {...a11yProps(0)}
                    className="box-shadow-none btn-tab-item"
                  />
                  <Tab
                    label={
                      <div className="">
                        <div className="content-img">
                          <GatsbyImage
                            image={
                              data.noRiskIcon.childImageSharp.gatsbyImageData
                            }
                            alt="Cloud Solar no risk"
                          />
                          <strong>No Risk</strong>
                        </div>
                      </div>
                    }
                    {...a11yProps(0)}
                    className="box-shadow-none btn-tab-item"
                  />
                  <Tab
                    label={
                      <div className="">
                        <div className="content-img">
                          <GatsbyImage
                            image={
                              data.noLimitsIcon.childImageSharp.gatsbyImageData
                            }
                            alt="Cloud Solar no limits"
                          />
                          <strong>No Limits</strong>
                        </div>
                      </div>
                    }
                    {...a11yProps(0)}
                    className="box-shadow-none btn-tab-item"
                  />
                </Tabs>
              </div>
              <div className="feature-tabpanels">
                <TabPanel value={value} index={0}>
                  <div className="featuredItem">
                    <div className="left product-content">
                      <GatsbyImage
                        image={data.poolingIcon.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar pool"
                      />
                      <h4>Revenue Pooling</h4>
                      <p>
                        Owning solar PV in a pool greatly increases earnings
                        potential of our owners, while decreasing the risks
                      </p>
                      <div className="item-btn btn-wrap">
                        <Link className="button" to="/how-it-works/">
                          READ MORE ABOUT HOW IT WORKS
                        </Link>
                      </div>
                    </div>
                    <div className="right product-image">
                      <GatsbyImage
                        image={data.pooling.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar pool"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="featuredItem">
                    <div className="left product-content">
                      <GatsbyImage
                        image={
                          data.installationsIcon.childImageSharp.gatsbyImageData
                        }
                        alt="Cloud Solar installations"
                      />
                      <h4>Low Cost Installations</h4>
                      <p>
                        We buy solar panels & components in large quantities to
                        create economies of scale to save money for our
                        customers.
                      </p>
                      <div className="item-btn btn-wrap">
                        <Link className="button" to="/how-it-works/">
                          READ MORE ABOUT HOW IT WORKS
                        </Link>
                      </div>
                    </div>
                    <div className="right product-image">
                      <GatsbyImage
                        image={
                          data.installations.childImageSharp.gatsbyImageData
                        }
                        alt="Cloud Solar installations"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="featuredItem">
                    <div className="left product-content">
                      <GatsbyImage
                        image={
                          data.maintenanceIcon.childImageSharp.gatsbyImageData
                        }
                        alt="Cloud Solar maintenance"
                      />
                      <h4>Maintenance Included</h4>
                      <p>
                        Maintenance and related downtime costs are absorbed by
                        the solar pool, so there is no added charge to our
                        customers.
                      </p>
                      <div className="item-btn btn-wrap">
                        <Link className="button" to="/how-it-works/">
                          READ MORE ABOUT HOW IT WORKS
                        </Link>
                      </div>
                    </div>
                    <div className="right product-image">
                      <GatsbyImage
                        image={data.maintenance.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar maintenance"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <div className="featuredItem">
                    <div className="left product-content">
                      <GatsbyImage
                        image={
                          data.insuranceIcon.childImageSharp.gatsbyImageData
                        }
                        alt="Cloud Solar insurance"
                      />
                      <h4>Insurance Included</h4>
                      <p>
                        All insurance costs are covered by the solar pool which
                        benefits from a large policy that protects our customers
                        and their assets against imminent risks.
                      </p>
                      <div className="item-btn btn-wrap">
                        <Link className="button" to="/how-it-works/">
                          READ MORE ABOUT HOW IT WORKS
                        </Link>
                      </div>
                    </div>
                    <div className="right product-image">
                      <GatsbyImage
                        image={data.insurance.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar insurance"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <div className="featuredItem">
                    <div className="left product-content">
                      <GatsbyImage
                        image={
                          data.requiredIcon.childImageSharp.gatsbyImageData
                        }
                        alt="Cloud Solar insurance"
                      />
                      <h4>No Roof/Land Required</h4>
                      <p>
                        Our products are perfectly suited for persons and
                        businesses who rent their locations, our remote solar
                        panels move when you move.
                      </p>
                      <div className="item-btn btn-wrap">
                        <Link className="button" to="/how-it-works/">
                          READ MORE ABOUT HOW IT WORKS
                        </Link>
                      </div>
                    </div>
                    <div className="right product-image">
                      <GatsbyImage
                        image={data.required.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar no land required"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <div className="featuredItem">
                    <div className="left product-content">
                      <GatsbyImage
                        image={
                          data.operationsIcon.childImageSharp.gatsbyImageData
                        }
                        alt="Cloud Solar operations"
                      />
                      <h4>Managed Operations</h4>
                      <p>
                        All operations and monitoring of the pool are overseen
                        by CloudSolar to achieve overall efficiencies.
                      </p>
                      <div className="item-btn btn-wrap">
                        <Link className="button" to="/how-it-works/">
                          READ MORE ABOUT HOW IT WORKS
                        </Link>
                      </div>
                    </div>
                    <div className="right product-image">
                      <GatsbyImage
                        image={data.operations.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar operations"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <div className="featuredItem">
                    <div className="left product-content">
                      <GatsbyImage
                        image={data.creditsIcon.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar credits"
                      />
                      <h4>Instant Credits</h4>
                      <p>
                        Instant credits with no waiting on offsets on your
                        electric bill. Use the money for whatever you wish.
                      </p>
                      <div className="item-btn btn-wrap">
                        <Link className="button" to="/how-it-works/">
                          READ MORE ABOUT HOW IT WORKS
                        </Link>
                      </div>
                    </div>
                    <div className="right product-image">
                      <GatsbyImage
                        image={data.credits.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar credits"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={7}>
                  <div className="featuredItem">
                    <div className="left product-content">
                      <GatsbyImage
                        image={data.liquidIcon.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar liquid investment"
                      />
                      <h4>Liquid Asset</h4>
                      <p>
                        Our products are easily converted into cash for short
                        term or long term needs, or emergencies.
                      </p>
                      <div className="item-btn btn-wrap">
                        <Link className="button" to="/how-it-works/">
                          READ MORE ABOUT HOW IT WORKS
                        </Link>
                      </div>
                    </div>
                    <div className="right product-image">
                      <GatsbyImage
                        image={data.liquid.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar liquid investment"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={8}>
                  <div className="featuredItem">
                    <div className="left product-content">
                      <GatsbyImage
                        image={data.noRiskIcon.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar no risk"
                      />
                      <h4>No Risk</h4>
                      <p>
                        No risk of owning the infrastructure, therefore all
                        liability falls with CloudSolar.
                      </p>
                      <div className="item-btn btn-wrap">
                        <Link className="button" to="/how-it-works/">
                          READ MORE ABOUT HOW IT WORKS
                        </Link>
                      </div>
                    </div>
                    <div className="right product-image">
                      <GatsbyImage
                        image={data.noRisk.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar no risk"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={9}>
                  <div className="featuredItem">
                    <div className="left product-content">
                      <GatsbyImage
                        image={
                          data.noLimitsIcon.childImageSharp.gatsbyImageData
                        }
                        alt="Cloud Solar no limits"
                      />
                      <h4>No Limits</h4>
                      <p>
                        Currently you are limited to owning 30 digital solar
                        panels. When the solar pool grows in size, we will allow
                        customers to purchase more panels.
                      </p>
                      <div className="item-btn btn-wrap">
                        <Link className="button" to="/how-it-works/">
                          READ MORE ABOUT HOW IT WORKS
                        </Link>
                      </div>
                    </div>
                    <div className="right product-image">
                      <GatsbyImage
                        image={data.noLimits.childImageSharp.gatsbyImageData}
                        alt="Cloud Solar no limits"
                      />
                    </div>
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
