const dev = {
  apiGateway: {
    REGION: 'us-east-1',
    emailURL: 'https://c3c6u0d3ql.execute-api.us-east-1.amazonaws.com/dev',
    leadsURL: 'https://c5gdkglkn9.execute-api.us-east-1.amazonaws.com/dev',
    ratesURL: 'https://zitc4tu1i8.execute-api.us-east-1.amazonaws.com/dev',
    statsURL: 'https://g7qaoc89ya.execute-api.us-east-1.amazonaws.com/dev',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_Svmbj5SIM',
    APP_CLIENT_ID: '6i0jad1bdfggkqfiannh0794vm',
    IDENTITY_POOL_ID: 'us-east-1:67a1c374-4969-4e19-9a30-aed3ba15533e',
  },
}

const prod = {
  apiGateway: {
    REGION: 'us-east-1',
    leadsURL: 'https://5hld65gnj1.execute-api.us-east-1.amazonaws.com/prod',
    emailURL: 'https://ih0ih22rc2.execute-api.us-east-1.amazonaws.com/prod',
    ratesURL: 'https://zdxw95ic0h.execute-api.us-east-1.amazonaws.com/prod',
    statsURL: 'https://odtquubdq4.execute-api.us-east-1.amazonaws.com/prod',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_Teb2DEB6l',
    APP_CLIENT_ID: '2gqfcqb2vlko991h0dv758n325',
    IDENTITY_POOL_ID: 'us-east-1:aba945f3-3bea-4f6e-bc97-dcaf0b7fc828',
  },
}

// Default to dev if not set
const awsConfig = process.env.GATSBY_STAGE === 'prod' ? prod : dev

export default {
  ...awsConfig,
}
