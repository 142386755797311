import awsConfig from '../config/awsConfig'

const amplifyConfig = {
  Auth: {
    mandatorySignIn: false,
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'leads',
        endpoint: awsConfig.apiGateway.leadsURL,
        region: awsConfig.apiGateway.REGION,
      },
      {
        name: 'email',
        endpoint: awsConfig.apiGateway.emailURL,
        region: awsConfig.apiGateway.REGION,
      },
      {
        name: 'rates',
        endpoint: awsConfig.apiGateway.ratesURL,
        region: awsConfig.apiGateway.REGION,
      },
      {
        name: 'stats',
        endpoint: awsConfig.apiGateway.statsURL,
        region: awsConfig.apiGateway.REGION,
      },
    ],
  },
}

export default {
  ...amplifyConfig,
}
