import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link, useStaticQuery, graphql } from 'gatsby'

export default function WhoWeAre() {
  const data = useStaticQuery(graphql`
    {
      montrose: file(relativePath: { eq: "montrose-logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 200, layout: FIXED)
        }
      }
      emera: file(relativePath: { eq: "emera-logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 200, layout: FIXED)
        }
      }
      cibc: file(relativePath: { eq: "cibc-logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 200, layout: FIXED)
        }
      }
      logo: file(relativePath: { eq: "small-logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      <section className="who-we-are-section section-gapping">
        <div className="container">
          <h2>
            OUR <span>PARTNERS</span>
          </h2>
          <div className="who-we-are-block">
            <div className="who-we-are left">
              <ul>
                <li>
                  <GatsbyImage
                    image={data.montrose.childImageSharp.gatsbyImageData}
                    alt="Montrose logo"
                  />
                </li>
                <li>
                  <GatsbyImage
                    image={data.emera.childImageSharp.gatsbyImageData}
                    alt="Emera logo"
                  />
                </li>
                <li>
                  <GatsbyImage
                    image={data.cibc.childImageSharp.gatsbyImageData}
                    alt="CIBC logo"
                  />
                </li>
              </ul>
              <div className="small-logo">
                <Link to="/">
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="Cloud Solar logo"
                  />
                </Link>
              </div>
            </div>
            <div className="who-we-are right">
              <h2>About CloudSolar </h2>
              <p>
                CloudSolar is the first green technology company in the
                Caribbean which seeks to revolutionize, democratize and innovate
                the way solar photovoltaic panels will be sold in the region.
                Our digital platform is the brain behind our operation with its
                superior functionality and ability to manage our entire business
                in the cloud for our customers.
              </p>
              <p>
                We are owned by the Montrose Holdings Group of companies which
                owns and operates several business entities in Barbados. We are
                also one of Barbados' largest private sector employers.{' '}
              </p>
              {!isExpanded ? null : (
                <p>
                  Our current installed capacity sits at 4 megawatts of
                  capacity, which is being rapidly expanded with the help of our
                  bankers and financing partners - CIBC First Caribbean and our
                  installation partner - Emera Caribbean Renewables Ltd. Both
                  companies were selected as preferred partners; with Emera
                  tasked to procure, manage, and install all of our
                  installations at the lowest cost, through their large network
                  of installations that stretches into the USA and Canada.{' '}
                </p>
              )}
              <button
                className="button read-more-btn"
                data-content="toggle-text"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? `SHOW LESS` : `READ MORE`}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
