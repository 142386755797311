import React from 'react'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core'
import Layout from '../components/layout'
import Banner from '../components/Home/banner'
import HowToStart from '../components/Home/how-to-start'
import Features from '../components/Home/features'
import WhoWeAre from '../components/Home/who-we-are'
import Projects from '../components/Home/projects'
import Monitor from '../components/Home/monitor'
import OurSolar from '../components/Home/our-solar'
import { Amplify, API } from 'aws-amplify'
import awsConfig from '../config/awsConfig'
import amplifyConfig from '../config/amplifyConfig'

fontAwesomeConfig.autoAddCss = true

Amplify.configure(amplifyConfig)
API.configure(awsConfig)

export default function Home() {
  return (
    <Layout>
      <Banner />
      <HowToStart />
      <Monitor />
      <Features />
      <WhoWeAre />
      <Projects />
      <OurSolar />
    </Layout>
  )
}
