import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

export default function Montior() {
  const data = useStaticQuery(graphql`{
  ourDashboard: file(relativePath: {eq: "our-dashboard-img.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 655, layout: CONSTRAINED)
    }
  }
}
`)
  return <>
    <section className="monitor-investment">
      <div className="container">
        <div className="our-dashboard-block">
          <div className="step-content">
            <h2 className="title-left">
              DIGITAL SOLAR <span>ASSETS</span>
            </h2>
            <div className="images-step mobile-img">
              <GatsbyImage
                image={data.ourDashboard.childImageSharp.gatsbyImageData}
                alt="Cloud Solar Dashboard"
              />
            </div>
            <p>
              With CloudSolar, you can purchase digital solar panels in low-cost, diversified
              solar farms all across Barbados. 
              Our business is a new, no-hassle and seamless method to earn money from the sun, 
              without physically having to install a solar panel on a roof. Through our proprietary online
              platform, our customers manage their remotely installed, digital
              solar panels and their generated solar credits. Every CloudSolar managed Solar PV system
              has its performance measured and recorded online in a solar
              pool; through this solar pool our customers are paid their proportional share of their
              solar credits from the solar earnings pool based on the number of panels they own.
            </p>
            <a className="button" href="https://app.cloud.solar/get-started/">
              GET STARTED
            </a>
          </div>
          <div className="images-step mobile-img-hide">
            <GatsbyImage
              image={data.ourDashboard.childImageSharp.gatsbyImageData}
              alt="Cloud Solar Dashboard"
            />
          </div>
        </div>
      </div>
    </section>
  </>;
}
